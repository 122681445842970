import request from '../index'

//获取首页轮播图的url列表
export function getSwiperList() {
	return request({
		url: '/homeSwiper',
		method: 'get',
	})
}
//获取首页-行业解决方案-领域列表
export function getHomeFieldList() {
	return request({
		url: '/plan/field',
		method: 'get',
		params: {
			page: 1,
			pageNum: 999,
		},
	})
}
//获取首页-行业解决方案列表
export function getHomePlanList(id) {
	return request({
		url: '/plan/list',
		method: 'get',
		params: {
			page: 1,
			pageNum: 999,
			fieldId: id,
		},
	})
}
//获取首页-产品中心列表
export function getHomeProductList(id) {
	return request({
		url: '/product/list',
		method: 'get',
		params: {
			page: 1,
			pageNum: 999,
			seriesId: id,
		},
	})
}
//获取首页-成功案例列表
export function getHomeCaseList() {
	return request({
		url: '/case/list',
		method: 'get',
		params: {
			page: 1,
			pageNum: 999,
		},
	})
}
//获取首页新闻资讯列表
export function getHomeNewsList() {
	return request({
		url: '/newsList' + '?page=1&pageNum=3',
		method: 'get',
	})
}
//获取新闻资讯列表，包括缩略图和简述信息；可分页，可根据日期进行筛选。
export function getNewsList(data) {
	return request({
		url: '/newsList',
		method: 'get',
		params: {
			page: data.page,
			pageNum: data.pageNum,
			startTime: data.startTime,
			endTime: data.endTime,
			keyword: data.keyword,
		},
	})
}
//获取新闻详情
export function getNewsDetails(id) {
	return request({
		url: '/news' + '?id=' + id,
		method: 'get',
	})
}
